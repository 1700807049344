<div
  class="flex flex-col px-10 py-4 border-2 border-gray-900/10 rounded-md"
  [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'"
>
  <div class="text-lg font-semibold my-1">{{ title }}</div>
  <div class="w-1/3">
    <input
      class="w-full h-10 border focus:outline-none rounded-md text-md p-2"
      [(ngModel)]="currentDateTime"
      type="datetime-local"
      (change)="onChange()"
    />
  </div>
</div>