<button class="valben-button" (click)="visible = true">Prikaži</button>

<p-dialog
    header=""
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '80%', height: '80%' }">
    <div class="p-5" [ngSwitch]="fileType">
        <img
            *ngSwitchCase="'img'"
            (error)="error = true"
            [src]="src"
            [alt]="src"
            class="object-contain w-ful h-fll"
        />

        <video
            *ngSwitchCase="'video'"
            controls
            (error)="error = true"
            class="object-contain w-full h-full">
            <source [src]="src" />
            Preglednik ne podržava ovaj video format
        </video>

        <div
            *ngSwitchDefault
            class="flex flex-col gap-4 justify-center items-center">
            <i class="pi pi-file text-[5rem]"></i>
            <p class="w-full text-start">{{ src || "Nije zadana nikakva datoteka." }}</p>
        </div>

        <p *ngIf="error" class="text-center">Datoteka je nepoznatog formata</p>
    </div>
</p-dialog>
