import { Component, OnDestroy, OnInit } from "@angular/core"
import { Observable, Subscription } from "rxjs"
import { MessageService } from "primeng/api"
import {
    ContentType,
    Column,
    CreateEmptySurveyErrorsObject,
    CreateSurvey,
    CreateSurveyKeysObject,
    ModifyEmptySurveyErrorsObject,
    ModifySurvey,
    ModifySurveyKeysObject,
    setErrors,
} from "../../models"
import { LoadingService, ContextService } from "../../services"

@Component({
    selector: "valben-survey",
    templateUrl: "./surveys.page.html",
    styles: [
        `
            :host {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        `,
    ],
})
export class SurveysPage implements OnInit, OnDestroy {
    tableValues: any[] = []

    resultSubscription: Subscription | undefined = new Subscription()
    itemsSubscription: Subscription | undefined = new Subscription()
    addItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getAddSurveySubject()?.asObservable()
    editItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getEditSurveySubject()?.asObservable()

    updateAddName: (value: any) => void
    updateAddDescription: (value: any) => void
    updateAddIsActive: (value: any) => void
    updateAddLink: (value: any) => void
    updateAddEndDate: (value: any) => void
    updateEditName: (value: any) => void
    updateEditDescription: (value: any) => void
    updateEditIsActive: (value: any) => void
    updateEditIsDraft: (value: any) => void
    updateEditIsDeleted: (value: any) => void
    updateEditLink: (value: any) => void
    updateEditEndDate: (value: any) => void
    createSurveyKeys = CreateSurveyKeysObject()
    modifySurveyKeys = ModifySurveyKeysObject()
    currentAddItem: () => CreateSurvey
    currentEditItem: () => ModifySurvey

    addFormType: string = ""
    editFormType: string = ""
    surveyKey: string = ""

    loading$ = this.loadingService.surveysLoading()
    cErrors: { [key: string]: boolean } = CreateEmptySurveyErrorsObject()
    mErrors: { [key: string]: boolean } = ModifyEmptySurveyErrorsObject()
    refresh = () => this.contextService.resetSurveys()

    cols: Column[] = [
        { field: "name", header: "Naslov", filterType: "text", bodyType: "string" },
        { field: "description", header: "Opis", filterType: "text", bodyType: "string" },
        { field: "createdByUser", header: "Kreiranje", filterType: "text", bodyType: "string" },
        { field: "modifiedByUser", header: "Zadnja izmjena", filterType: "text", bodyType: "string" },
        { field: "createdDate", header: "Datum kreiranja", filterType: "date", bodyType: "date" },
        { field: "modifiedDate", header: "Datum izmjene", filterType: "date", bodyType: "date" },
        { field: "isDraft", header: "Skica", filterType: "boolean", bodyType: "boolean" },
        { field: "isActive", header: "Aktivan", filterType: "boolean", bodyType: "boolean" },
        { field: "version", header: "Verzija", filterType: "numeric", bodyType: "number" },
        { field: "endDate", header: "Datum završetka", filterType: "date", bodyType: "date" },

    ]

    contentType: ContentType = ContentType.SEARCH

    editEnabled = false

    setContentType(value: string) {
        switch (value) {
            case "Search":
                if (this.contentType !== ContentType.SEARCH) {
                    this.contentType = ContentType.SEARCH
                }
                break
            case "Add":
                if (this.contentType !== ContentType.ADD) {
                    this.contentType = ContentType.ADD
                }
                break
            case "Edit":
                if (this.contentType !== ContentType.EDIT) {
                    this.contentType = ContentType.EDIT
                }
                break
            default:
                if (this.contentType !== ContentType.SEARCH) {
                    this.contentType = ContentType.SEARCH
                }
                break
        }
    }

    constructor(
        private messageService: MessageService,
        private contextService: ContextService,
        private loadingService: LoadingService
    ) {
        this.addFormType = this.contextService.getAddKey()
        this.editFormType = this.contextService.getEditKey()
        this.surveyKey = this.contextService.getSurveysKey()
        this.updateAddName = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.createSurveyKeys.Name, value, this.addFormType)
        }
        this.updateAddDescription = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.createSurveyKeys.Description, value, this.addFormType)
        }
        this.updateAddIsActive = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.createSurveyKeys.IsActive, value, this.addFormType)
        }
        this.updateAddLink = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.createSurveyKeys.Link, value, this.addFormType)
        }
        this.updateAddEndDate = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.createSurveyKeys.EndDate, value, this.addFormType)
        }
        this.updateEditName = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.modifySurveyKeys.Name, value, this.editFormType)
        }
        this.updateEditDescription = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.modifySurveyKeys.Description, value, this.editFormType)
        }
        this.updateEditIsActive = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.modifySurveyKeys.IsActive, value, this.editFormType)
        }
        this.updateEditIsDraft = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.modifySurveyKeys.IsDraft, value, this.editFormType)
        }
        this.updateEditIsDeleted = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.modifySurveyKeys.IsDeleted, value, this.editFormType)
        }
        this.updateEditLink = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.modifySurveyKeys.Link, value, this.editFormType)
        }
        this.updateEditEndDate = (value: any) => {
            this.contextService.setItem(this.surveyKey, this.modifySurveyKeys.EndDate, value, this.editFormType)
        }
        this.currentAddItem = () => this.contextService.getAddSurveySubject()?.value
        this.currentEditItem = () => this.contextService.getEditSurveySubject()?.value
    }

    ngOnInit(): void {
        this.itemsSubscription = this.contextService.getSurveysSubject()?.subscribe(res => {
            this.tableValues = JSON.parse(JSON.stringify(res))
            this.tableValues.forEach(x => {
                return (x.createdDate = new Date(x.createdDate))
            })
        })
        this.resultSubscription = this.contextService.getSurveysResultSubject()?.subscribe(res => {
            if (res !== null) {
                if (res.formType === this.editFormType && res.success) {
                    this.editEnabled = false
                    this.setContentType("Search")
                }
                res.formType === this.editFormType
                    ? setErrors(res.errorFields, this.mErrors)
                    : setErrors(res.errorFields, this.cErrors)
                this.messageService.add({
                    severity: res.error ? "error" : "success",
                    summary: res.error ? "Error" : "Success",
                    detail: res.message,
                })
            }
        })
    }

    ngOnDestroy(): void {
        this.tableValues = []
        this.resultSubscription?.unsubscribe()
        this.itemsSubscription?.unsubscribe()
    }

    editOnClick(masterId: string) {
        this.contextService.setCurrentEditSurvey(masterId)
        this.editEnabled = true
        this.setContentType("Edit")
    }

    save() {
        this.contextService.saveSurvey()
    }

    draft() {
        this.contextService.draftSurvey()
    }

    modify() {
        this.contextService.modifySurvey()
    }
}
