import { Component, Input, OnInit } from "@angular/core"
import { Observable } from "rxjs"

@Component({
    selector: "valben-date-time-picker",
    templateUrl: "./date-time-picker.component.html",
})
export class DateTimePickerComponent implements OnInit {
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() title: string = ""
    @Input() error: boolean = false

    currentDateTime: string = ""

    ngOnInit(): void {
        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null && value[this.key] !== this.currentDateTime) {
                this.currentDateTime = value[this.key]
            }
        })
    }

    onChange() {
        this.update(this.currentDateTime)
    }
}
