import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { Observable } from "rxjs"
import {
    Dependencies,
    PickListItem,
    EmployeeFilter,
    createFilterGroup,
    EmployeeType,
    create,
    createPositions,
} from "../../models"
import { ContextService } from "src/app/services"
import { PickListSide } from "src/app/models/pickList.model"
import * as XLSX from "xlsx"

enum DependenciesState {
    NONE = "none",
    ADD = "add",
    ORGUNITS = "orgunits",
    POSITIONS = "positions",
    COSTCENTERS = "costcenters",
    EMPLOYEECONTRACTS = "employeecontracts",
    GENDERS = "genders",
    EMPLOYEES = "employees",
}

const _states = [
    { value: DependenciesState.ORGUNITS, label: "Organizacijske jedinice" },
    { value: DependenciesState.POSITIONS, label: "Pozicije" },
    { value: DependenciesState.COSTCENTERS, label: "Mjesta troška" },
    { value: DependenciesState.EMPLOYEECONTRACTS, label: "Tipovi odnosa" },
    { value: DependenciesState.GENDERS, label: "Spolovi" },
]

@Component({
    selector: "valben-dependencies",
    templateUrl: "./dependencies.component.html",
})
export class DependenciesComponent implements OnInit, OnDestroy {
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() currentItem!: () => any | undefined
    @Input() title: string = ""
    @Input() error: boolean = false
    dependenciesState!: Dependencies
    currentEditedFilterIndex: number = -1
    state!: DependenciesState
    dropdownState = undefined
    states = JSON.parse(JSON.stringify(_states))
    filteredEmployees!: PickListItem[]
    filteredIncludedEmployeesCount: number = 0
    allEmployeeContracts: EmployeeType[] = []

    private resetStates(): void {
        this.states = JSON.parse(JSON.stringify(_states))
    }

    addFilteredEmployee(): void {
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        let incCount = 0
        fg.includedEmployees = []
        fg.excludedEmployees = []

        this.filteredEmployees.forEach(e => {
            if (e.currentSide === PickListSide.SOURCE) {
                incCount++
            }
            if (e.currentSide !== e.originalSide) {
                if (e.currentSide === PickListSide.SOURCE) {
                    fg.includedEmployees.push(e.item.masterId)
                } else {
                    fg.excludedEmployees.push(e.item.masterId)
                }
            }
        })
        this.update(fg)
        this.filteredIncludedEmployeesCount = incCount
    }

    addFilterGroup(): void {
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        fg.filterGroups.push(createFilterGroup())
        this.update(fg)
    }

    removeFilterGroup(index: number): void {
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        fg.filterGroups.splice(index, 1)
        this.update(fg)
    }

    addFilter(index: number): void {
        this.states = []
        if (this.dependenciesState.filterGroups[index].orgUnits === null) {
            this.states.push({ value: DependenciesState.ORGUNITS, label: "Organizacijske jedinice" })
        }
        if (this.dependenciesState.filterGroups[index].positions === null) {
            this.states.push({ value: DependenciesState.POSITIONS, label: "Pozicije" })
        }
        if (this.dependenciesState.filterGroups[index].costCenters === null) {
            this.states.push({ value: DependenciesState.COSTCENTERS, label: "Mjesta troška" })
        }
        if (this.dependenciesState.filterGroups[index].contracts === null) {
            this.states.push({ value: DependenciesState.EMPLOYEECONTRACTS, label: "Tipovi odnosa" })
        }
        if (this.dependenciesState.filterGroups[index].genders === null) {
            this.states.push({ value: DependenciesState.GENDERS, label: "Spolovi" })
        }
        this.state = DependenciesState.ADD
        this.currentEditedFilterIndex = index
    }

    selectStateOnChange(event: any): void {
        this.state = event.value.value as DependenciesState
    }

    closeOnClick(): void {
        if (this.state === DependenciesState.ORGUNITS) {
            this.contextService.ResetOrgUnitTree()
        } else if (this.state === DependenciesState.POSITIONS) {
            this.contextService.ResetPositionsTree()
        } else if (this.state === DependenciesState.COSTCENTERS) {
            this.contextService.ResetCostCenters()
        } else if (this.state === DependenciesState.EMPLOYEECONTRACTS) {
            this.contextService.ResetEmployeeContracts()
        } else if (this.state === DependenciesState.GENDERS) {
            this.contextService.ResetGenders()
        }
        this.state = DependenciesState.NONE
        this.currentEditedFilterIndex = -1
        this.resetStates()
    }

    confirmOrgUnitsOnClick(): void {
        if (this.currentEditedFilterIndex !== -1) {
            const ou = this.contextService.OrgUnitsPayload()
            const fg = JSON.parse(JSON.stringify(this.dependenciesState))
            fg.filterGroups[this.currentEditedFilterIndex].orgUnits = ou
            this.update(fg)
            this.state = DependenciesState.NONE
            this.currentEditedFilterIndex = -1
            this.resetStates()
            this.contextService.ResetOrgUnitTree()
        }
    }

    confirmPositionsOnClick(): void {
        if (this.currentEditedFilterIndex !== -1) {
            const p = this.contextService.PositionsPayload()
            const fg = JSON.parse(JSON.stringify(this.dependenciesState))
            fg.filterGroups[this.currentEditedFilterIndex].positions = p
            this.update(fg)
            this.state = DependenciesState.NONE
            this.currentEditedFilterIndex = -1
            this.resetStates()
            this.contextService.ResetPositionsTree()
        }
    }

    confirmCostCentersOnClick(): void {
        if (this.currentEditedFilterIndex !== -1) {
            const selectedCostCenters = this.contextService.CurrentCostCenters()
            const fg = JSON.parse(JSON.stringify(this.dependenciesState))
            fg.filterGroups[this.currentEditedFilterIndex].costCenters = selectedCostCenters
            this.update(fg)
            this.state = DependenciesState.NONE
            this.currentEditedFilterIndex = -1
            this.resetStates()
            this.contextService.ResetCostCenters()
        }
    }

    confirmEmployeeContractsOnClick(): void {
        if (this.currentEditedFilterIndex !== -1) {
            const selectedEmployeeContracts = this.contextService.CurrentEmployeeContracts()
            const fg = JSON.parse(JSON.stringify(this.dependenciesState))
            fg.filterGroups[this.currentEditedFilterIndex].contracts = selectedEmployeeContracts
            this.update(fg)
            this.state = DependenciesState.NONE
            this.currentEditedFilterIndex = -1
            this.resetStates()
            this.contextService.ResetEmployeeContracts()
        }
    }

    confirmGendersOnClick(): void {
        if (this.currentEditedFilterIndex !== -1) {
            const selectedGenders = this.contextService.CurrentGenders()
            const fg = JSON.parse(JSON.stringify(this.dependenciesState))
            fg.filterGroups[this.currentEditedFilterIndex].genders = selectedGenders
            this.update(fg)
            this.state = DependenciesState.NONE
            this.currentEditedFilterIndex = -1
            this.resetStates()
            this.contextService.ResetGenders()
        }
    }

    editOrgUnitsOnClick(index: number): void {
        const ctr = JSON.parse(JSON.stringify(this.dependenciesState.filterGroups[index].orgUnits?.treeRepresentation))
        this.contextService.SetCurrentOrgUnitTreeRepresentation(ctr)
        const rawOrgUnits = this.contextService.RawOrgUnits()
        if (rawOrgUnits) {
            const tr = create(ctr, rawOrgUnits)
            this.contextService.UpdateCurrentOrgUnitTree(tr, ctr.opsCsSelected)
        }
        this.state = DependenciesState.ORGUNITS
        this.currentEditedFilterIndex = index
    }

    editPositionsOnClick(index: number): void {
        const ctr = JSON.parse(JSON.stringify(this.dependenciesState.filterGroups[index].positions?.treeRepresentation))
        this.contextService.SetCurrentPositionsTreeRepresentation(ctr)
        const rawPositions = this.contextService.RawPositions()
        if (rawPositions) {
            const tr = createPositions(ctr, rawPositions)
            this.contextService.UpdateCurrentPositionsTree(tr)
        }
        this.state = DependenciesState.POSITIONS
        this.currentEditedFilterIndex = index
    }

    editCostCentersOnClick(index: number): void {
        const ctr = JSON.parse(JSON.stringify(this.dependenciesState.filterGroups[index].costCenters))
        this.contextService.UpdateCurrentCostCenters(ctr)
        this.state = DependenciesState.COSTCENTERS
        this.currentEditedFilterIndex = index
    }

    editEmployeeContractsOnClick(index: number): void {
        const ctr = JSON.parse(JSON.stringify(this.dependenciesState.filterGroups[index].contracts))
        this.contextService.UpdateCurrentEmployeeContracts(ctr)
        this.state = DependenciesState.EMPLOYEECONTRACTS
        this.currentEditedFilterIndex = index
    }

    editGendersOnClick(index: number): void {
        const ctr = JSON.parse(JSON.stringify(this.dependenciesState.filterGroups[index].genders))
        this.contextService.UpdateCurrentGenders(ctr)
        this.state = DependenciesState.GENDERS
        this.currentEditedFilterIndex = index
    }

    removeOrgUnitsOnClick(index: number): void {
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        fg.filterGroups[index].orgUnits = null
        this.update(fg)
    }

    removePositionsOnClick(index: number): void {
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        fg.filterGroups[index].positions = null
        this.update(fg)
    }

    removeCostCentersOnClick(index: number): void {
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        this.contextService.ResetCostCenters()
        fg.filterGroups[index].costCenters = null
        this.update(fg)
    }

    removeEmployeeContractsOnClick(index: number): void {
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        this.contextService.ResetEmployeeContracts()
        fg.filterGroups[index].contracts = null
        this.update(fg)
    }

    removeGendersOnClick(index: number): void {
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        this.contextService.ResetGenders()
        fg.filterGroups[index].genders = null
        this.update(fg)
    }

    filteringDone(): void {
        this.filteredEmployees = []
        this.state = DependenciesState.EMPLOYEES
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        fg.includedEmployees = []
        fg.excludedEmployees = []
        this.update(fg)
        this.contextService.CallFilteredEmployees(fg)
    }

    stateToNone(): void {
        this.state = DependenciesState.NONE
        this.filteredIncludedEmployeesCount = 0
        this.filteredEmployees = []
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        fg.includedEmployees = null
        fg.excludedEmployees = null
        this.update(fg)
        this.contextService.ResetFilteredEmployees()
    }

    exportExcel(): void {
        const workSheetData: { ime: string; mbrad: string }[] = []
        this.filteredEmployees.forEach(e => {
            if (e.currentSide === PickListSide.SOURCE) {
                const splitted: string[] = e.item.mbradWithName.split("(")
                workSheetData.push({
                    ime: splitted[1].replace(")", "").trim(),
                    mbrad: splitted[0].trim(),
                })
            }
        })
        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(workSheetData)
        const workBook: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "Odabrani zaposlenici")
        XLSX.writeFile(workBook, "odabrani_zaposlenici.xlsx")
    }

    constructor(private contextService: ContextService) {}

    ngOnInit(): void {
        this.state = DependenciesState.NONE

        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null) {
                if (value[this.key]) {
                    // TODO: first compare if the value is different
                    this.dependenciesState = value[this.key]
                    if (
                        value[this.key].filterGroups.length === 0 &&
                        value[this.key].includedEmployees === null &&
                        value[this.key].excludedEmployees === null
                    ) {
                        this.state = DependenciesState.NONE
                    }
                }
            }
        })

        this.contextService.FilteredEmployees().subscribe(res => {
            if (
                this.dependenciesState.includedEmployees === null ||
                this.dependenciesState.excludedEmployees === null ||
                res.included === null ||
                res.excluded === null
            ) {
                return
            }
            this.filteredIncludedEmployeesCount = res.included.length
            this.filteredEmployees = res.included
                .map((e: EmployeeFilter) => ({
                    item: e,
                    selected: false,
                    filtered: true,
                    originalSide: PickListSide.SOURCE,
                    currentSide: this.dependenciesState.excludedEmployees?.includes(e.masterId)
                        ? PickListSide.TARGET
                        : PickListSide.SOURCE,
                }))
                .concat(
                    res.excluded.map((e: EmployeeFilter) => ({
                        item: e,
                        selected: false,
                        filtered: true,
                        originalSide: PickListSide.TARGET,
                        currentSide: this.dependenciesState.includedEmployees?.includes(e.masterId)
                            ? PickListSide.SOURCE
                            : PickListSide.TARGET,
                    }))
                )
        })

        this.contextService
            .getAwardsResultSubject()
            ?.asObservable()
            .subscribe(res => {
                if (res && res.success) {
                    this.state = DependenciesState.NONE
                    this.currentEditedFilterIndex = -1
                    this.resetStates()
                    this.filteredEmployees = []
                }
            })

        this.contextService.EmployeeContractsObservable().subscribe(res => {
            if (res) this.allEmployeeContracts = res
        })
    }

    ngOnDestroy(): void {
        const fg = JSON.parse(JSON.stringify(this.dependenciesState))
        fg.excludedEmployees = null
        fg.includedEmployees = null
        this.update(fg)
        this.contextService.ResetFilteredEmployees()
        this.contextService.ResetOrgUnitTree()
        this.contextService.ResetPositionsTree()
        this.contextService.ResetCostCenters()
        this.contextService.ResetEmployeeContracts()
        this.contextService.ResetGenders()
    }
}
