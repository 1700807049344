import { Component, OnDestroy, OnInit } from "@angular/core"
import { Observable, Subscription } from "rxjs"
import { MessageService } from "primeng/api"
import {
    ContentType,
    Column,
    CategoryTypes,
    CreateEmptyPerkErrorsObject,
    CreatePerk,
    CreatePerkKeysObject,
    ModifyEmptyPerkErrorsObject,
    ModifyPerk,
    ModifyPerkKeysObject,
    FileContent,
    setErrors,
} from "../../models"
import { LoadingService, ContextService } from "../../services"

@Component({
    selector: "valben-perks",
    templateUrl: "./perks.page.html",
    styles: [
        `
            :host {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        `,
    ],
})
export class PerksPage implements OnInit, OnDestroy {
    tableValues: any[] = []

    resultSubscription: Subscription | undefined = new Subscription()
    itemsSubscription: Subscription | undefined = new Subscription()
    addItemObservable: () => Observable<any> | undefined = () => this.contextService.getAddPerkSubject()?.asObservable()
    editItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getEditPerkSubject()?.asObservable()

    updateAddNames: (value: any) => void
    updateAddDescriptions: (value: any) => void
    updateAddIsActive: (value: any) => void
    updateAddImage: (value: FileContent) => void
    updateAddLink: (value: any) => void
    updateAddContact: (value: any) => void
    updateAddCategories: (value: any) => void
    updateAddLocations: (value: any) => void
    updateAddDependencies: (value: any) => void

    updateEditNames: (value: any) => void
    updateEditDescriptions: (value: any) => void
    updateEditIsActive: (value: any) => void
    updateEditIsDraft: (value: any) => void
    updateEditIsDeleted: (value: any) => void
    updateEditImage: (value: FileContent) => void
    updateEditLink: (value: any) => void
    updateEditContact: (value: any) => void
    updateEditCategories: (value: any) => void
    updateEditLocations: (value: any) => void
    updateEditDependencies: (value: any) => void

    createPerkKeys = CreatePerkKeysObject()
    modifyPerkKeys = ModifyPerkKeysObject()
    currentAddItem: () => CreatePerk
    currentEditItem: () => ModifyPerk

    addFormType: string = ""
    editFormType: string = ""
    perkKey: string = ""
    categoriesKey: string = CategoryTypes.Perk

    loading$ = this.loadingService.perksLoading()
    cErrors: { [key: string]: boolean } = CreateEmptyPerkErrorsObject()
    mErrors: { [key: string]: boolean } = ModifyEmptyPerkErrorsObject()
    refresh = () => this.contextService.resetPerks()

    cols: Column[] = [
        { field: "names", header: "Naslov", filterType: "text", default: "hr", bodyType: "langs", filterable: true },
        {
            field: "descriptions",
            header: "Opis",
            filterType: "text",
            default: "hr",
            bodyType: "langs",
            filterable: true,
        },
        { field: "createdByUser", header: "Kreiranje", filterType: "text", bodyType: "string" },
        { field: "modifiedByUser", header: "Zadnja izmjena", filterType: "text", bodyType: "string" },
        { field: "createdDate", header: "Datum kreiranja", filterType: "date", bodyType: "date" },
        { field: "modifiedDate", header: "Datum izmjene", filterType: "date", bodyType: "date" },
        {
            field: "contact",
            header: "Kontakt",
            filterType: "text",
            default: "phoneNumber",
            bodyType: "langs",
            filterable: true,
        },
        { field: "isDraft", header: "Skica", filterType: "boolean", bodyType: "boolean" },
        { field: "isActive", header: "Aktivan", filterType: "boolean", bodyType: "boolean" },
        { field: "version", header: "Verzija", filterType: "numeric", bodyType: "number" },
        { field: "imageUrl", header: "Slika", bodyType: "file", sortable: false, filterable: false },
        { field: "categoryIds", header: "Kategorije", filterType: "text", bodyType: "modal" },
        { field: "locationIds", header: "Lokacije", bodyType: "mapIds", filterable: false, sortable: false },
        { field: "dependsOn", header: "Personalizacija", bodyType: "modal", sortable: false, filterable: false },
    ]

    contentType: ContentType = ContentType.SEARCH

    editEnabled = false

    setContentType(value: string) {
        switch (value) {
            case "Search":
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
            case "Add":
                if (!(this.contentType === ContentType.ADD)) {
                    this.contentType = ContentType.ADD
                }
                break
            case "Edit":
                if (!(this.contentType === ContentType.EDIT)) {
                    this.contentType = ContentType.EDIT
                }
                break
            default:
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
        }
    }

    constructor(
        private contextService: ContextService,
        private loadingService: LoadingService,
        private messageService: MessageService
    ) {
        this.addFormType = this.contextService.getAddKey()
        this.editFormType = this.contextService.getEditKey()
        this.perkKey = this.contextService.getPerksKey()

        this.updateAddNames = (value: any) => {
            this.contextService.setItem(this.perkKey, this.createPerkKeys.Names, value, this.addFormType)
        }
        this.updateAddDescriptions = (value: any) => {
            this.contextService.setItem(this.perkKey, this.createPerkKeys.Descriptions, value, this.addFormType)
        }
        this.updateAddIsActive = (value: any) => {
            this.contextService.setItem(this.perkKey, this.createPerkKeys.IsActive, value, this.addFormType)
        }
        this.updateAddImage = (value: FileContent) => {
            this.contextService.setItem(this.perkKey, this.createPerkKeys.Image, value, this.addFormType)
        }
        this.updateAddLink = (value: any) => {
            this.contextService.setItem(this.perkKey, this.createPerkKeys.Link, value, this.addFormType)
        }
        this.updateAddContact = (value: any) => {
            this.contextService.setItem(this.perkKey, this.createPerkKeys.Contact, value, this.addFormType)
        }
        this.updateAddCategories = (value: any) => {
            this.contextService.setItem(this.perkKey, this.createPerkKeys.CategoryIds, value, this.addFormType)
        }
        this.updateAddLocations = (value: any) => {
            this.contextService.setItem(this.perkKey, this.createPerkKeys.LocationIds, value, this.addFormType)
        }
        this.updateAddDependencies = (value: any) => {
            this.contextService.setItem(this.perkKey, this.createPerkKeys.Dependencies, value, this.addFormType)
        }
        this.updateEditNames = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.Names, value, this.editFormType)
        }
        this.updateEditDescriptions = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.Descriptions, value, this.editFormType)
        }
        this.updateEditIsActive = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.IsActive, value, this.editFormType)
        }
        this.updateEditIsDraft = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.IsDraft, value, this.editFormType)
        }
        this.updateEditIsDeleted = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.IsDeleted, value, this.editFormType)
        }
        this.updateEditImage = (value: FileContent) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.Image, value, this.editFormType)
        }
        this.updateEditLink = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.Link, value, this.editFormType)
        }
        this.updateEditContact = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.Contact, value, this.editFormType)
        }
        this.updateEditCategories = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.CategoryIds, value, this.editFormType)
        }
        this.updateEditLocations = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.LocationIds, value, this.editFormType)
        }
        this.updateEditDependencies = (value: any) => {
            this.contextService.setItem(this.perkKey, this.modifyPerkKeys.Dependencies, value, this.editFormType)
        }

        this.currentAddItem = () => this.contextService.getAddPerkSubject()?.value
        this.currentEditItem = () => this.contextService.getEditPerkSubject()?.value
    }

    ngOnInit(): void {
        this.itemsSubscription = this.contextService.getPerksSubject()?.subscribe(res => {
            this.tableValues = JSON.parse(JSON.stringify(res))
            this.tableValues.forEach(x => {
                return (
                    (x.createdDate = new Date(x.createdDate)),
                    (x.categoryIds = this.contextService.categoriesNames(x.categoryIds))
                )
            })
        })
        this.resultSubscription = this.contextService.getPerksResultSubject()?.subscribe(res => {
            if (res !== null) {
                if (res.formType === this.editFormType && res.success) {
                    this.editEnabled = false
                    this.setContentType("Search")
                }
                res.formType === this.editFormType
                    ? setErrors(res.errorFields, this.mErrors)
                    : setErrors(res.errorFields, this.cErrors)
                this.messageService.add({
                    severity: res.error ? "error" : "success",
                    summary: res.error ? "Error" : "Success",
                    detail: res.message,
                })
            }
        })
    }

    ngOnDestroy(): void {
        this.tableValues = []
        this.resultSubscription?.unsubscribe()
        this.itemsSubscription?.unsubscribe()
    }

    editOnClick(masterId: string) {
        this.contextService.setCurrentEditPerk(masterId)
        this.editEnabled = true
        this.setContentType("Edit")
    }

    save() {
        this.contextService.savePerk()
    }

    draft() {
        this.contextService.draftPerk()
    }

    modify() {
        this.contextService.modifyPerk()
    }
}
