import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, first } from "rxjs"
import {
    AwardsService,
    CategoryService,
    BenefitsService,
    PerksService,
    SurveyService,
    NewsService,
    PromotionalContentService,
    EmployeeService,
    LocationsService,
} from "."
import {
    Category,
    IContext,
    createContext,
    CreateCategory,
    setCurrentEditedMasterId,
    ModifyCategoryFactory,
    ModifyBenefitFactory,
    IResult,
    ResultFactory,
    ModifyAwardFactory,
    ModifyPerkFactory,
    ModifySurveyFactory,
    ModifyNewsFactory,
    ModifyObject,
    ModifyObjectFactory,
    Node,
    OrgUnit,
    FolderStructureNode,
    OrgUnitsRepresentation,
    OpsCsSelected,
    CostCenter,
    OrgUnits,
    Positions,
    PositionsRepresentation,
    PositionsNode,
    PositionsPayload,
    EmployeeFilterResult,
    Dependencies,
    PromotionalContent,
    CreatePromotionalContent,
    ModifyPromotionalContentFactory,
    CostCenters,
    Location,
    ModifyLocationFactory,
    CreateLocation,
    EmployeeType,
    EmployeeContracts,
} from "../models"
import { JsonPatchDocumentGenerator } from "../helpers/JsonPatchDocumentGenerator"
import { DependenciesService } from "./dependencies.service"

@Injectable({
    providedIn: "root",
})
export class ContextService {
    private _context: IContext
    private jsonPatchDocumentGenerator: JsonPatchDocumentGenerator = new JsonPatchDocumentGenerator()
    private formTypes: string[] = ["add", "edit"]
    private ignoreFields: string[] = [
        "_id",
        "masterId",
        "createdByUser",
        "modifiedByUser",
        "createdDate",
        "version",
        "level",
    ]

    constructor(
        private categoryService: CategoryService,
        private awardsService: AwardsService,
        private benefitService: BenefitsService,
        private perksService: PerksService,
        private surveysService: SurveyService,
        private newsService: NewsService,
        private promContentService: PromotionalContentService,
        private dependenciesService: DependenciesService,
        private employeeService: EmployeeService,
        private locationsService: LocationsService
    ) {
        this._context = createContext()

        this.categoryService
            .Get()
            .pipe(first())
            .subscribe((categories: Category[]) => {
                this.setCategories(categories)
            })

        this.awardsService
            .Get()
            .pipe(first())
            .subscribe((awards: any[]) => {
                this.setAwards(awards)
            })

        this.benefitService
            .Get()
            .pipe(first())
            .subscribe((benefit: any[]) => {
                this.setBenefits(benefit)
            })

        this.perksService
            .Get()
            .pipe(first())
            .subscribe((perks: any[]) => {
                this.setPerks(perks)
            })

        this.surveysService
            .Get()
            .pipe(first())
            .subscribe((surveys: any[]) => {
                this.setSurveys(surveys)
            })

        this.newsService
            .Get()
            .pipe(first())
            .subscribe((news: any[]) => {
                this.setNews(news)
            })

        this.promContentService
            .Get()
            .pipe(first())
            .subscribe(pcon => {
                this.setPromContent(pcon)
            })

        this.locationsService
            .Get()
            .pipe(first())
            .subscribe(locations => {
                this.setLocations(locations)
            })
    }

    CallFilteredEmployees(depends: Dependencies): void {
        this.employeeService.GetFilterResult(depends)
    }

    FilteredEmployees(): Observable<EmployeeFilterResult> {
        return this.employeeService.FilteredEmployees().asObservable()
    }

    ResetFilteredEmployees(): void {
        this.employeeService.ResetFilteredEmployees()
    }

    OrgUnits(): Observable<Node | undefined> {
        return this.dependenciesService.OrgUnitTree().asObservable()
    }

    RawOrgUnits(): Node | undefined {
        return this.dependenciesService.OrgUnitTree().value
    }

    Positions(): Observable<Positions | undefined> {
        return this.dependenciesService.Positions().asObservable()
    }

    OrgUnitTree(): Node | undefined {
        return this.dependenciesService.OrgUnitTree().value
    }

    RawPositions(): Positions | undefined {
        return this.dependenciesService.Positions().value
    }

    CostCenters(): CostCenter[] {
        return this.dependenciesService.CostCenters().value
    }

    CostCentersObservable(): Observable<CostCenter[]> {
        return this.dependenciesService.CostCenters().asObservable()
    }

    EmployeeContractsObservable(): Observable<EmployeeType[]> {
        return this.dependenciesService.EmployeeContracts().asObservable()
    }

    EmployeeContracts(): EmployeeType[] {
        return this.dependenciesService.EmployeeContracts().value
    }

    CurrentOrgUnitTreeRepresentation(): OrgUnitsRepresentation {
        return this.dependenciesService.CurrentOrgUnitTreeRepresentation()
    }

    CurrentPositionsTreeRepresentation(): PositionsRepresentation {
        return this.dependenciesService.CurrentPositionsTreeRepresentation()
    }

    CurrentCostCenters(): CostCenters | undefined {
        return this.dependenciesService.CurrentCostCenters()
    }

    CurrentEmployeeContracts(): EmployeeContracts | undefined {
        return this.dependenciesService.CurrentEmployeeContracts()
    }

    CurrentGenders(): string[] | undefined {
        return this.dependenciesService.CurrentGenders()
    }

    SetCurrentOrgUnitTreeRepresentation(treeRepresentation: OrgUnitsRepresentation): void {
        this.dependenciesService.SetCurrentOrgUnitTreeRepresentation(treeRepresentation)
    }

    SetCurrentPositionsTreeRepresentation(treeRepresentation: PositionsRepresentation): void {
        this.dependenciesService.SetCurrentPositionsTreeRepresentation(treeRepresentation)
    }

    UpdateCurrentOrgUnitTree(tree: FolderStructureNode, opsCsSelected: OpsCsSelected): void {
        this.dependenciesService.UpdateCurrentOrgUnitTree(tree, opsCsSelected)
    }

    UpdateCurrentPositionsTree(tree: PositionsNode): void {
        this.dependenciesService.UpdateCurrentPositionsTree(tree)
    }

    UpdateCurrentCostCenters(costCenters: CostCenters): void {
        this.dependenciesService.UpdateCurrentCostCenters(costCenters)
    }

    UpdateCurrentEmployeeContracts(employeeContracts: EmployeeContracts): void {
        this.dependenciesService.UpdateCurrentEmployeeContracts(employeeContracts)
    }

    UpdateCurrentGenders(genders: string[]): void {
        this.dependenciesService.UpdateCurrentGenders(genders)
    }

    ResetOrgUnitTree(): void {
        this.dependenciesService.ResetCurrentOrgUnitTree()
    }

    ResetPositionsTree(): void {
        this.dependenciesService.ResetCurrentPositionsTree()
    }

    ResetCostCenters(): void {
        this.dependenciesService.ResetCurrentCostCenters()
    }

    ResetEmployeeContracts(): void {
        this.dependenciesService.ResetCurrentEmployeeContracts()
    }

    ResetGenders(): void {
        this.dependenciesService.ResetCurrentGenders()
    }

    OrgUnitsPayload(): OrgUnits {
        return this.dependenciesService.GetOrgUnitsPayload()
    }

    PositionsPayload(): PositionsPayload {
        return this.dependenciesService.GetPositionsPayload()
    }

    IsOps(o: OrgUnit): boolean {
        return this.dependenciesService.IsOps(o)
    }

    IsCompany(o: OrgUnit): boolean {
        return this.dependenciesService.IsCompany(o)
    }

    setAddItem(contextKey: string, key: string, value: any) {
        if (this._context.get(contextKey) !== undefined) {
            this._context.get(contextKey)!.setFieldAdd(key, value)
        }
    }

    setEditItem(contextKey: string, key: string, value: any) {
        if (this._context.get(contextKey) !== undefined) {
            this._context.get(contextKey)!.setFieldEdit(key, value)
        }
    }

    setItem(contextKey: string, key: string, value: any, formType: string) {
        if (formType === this.formTypes[0]) {
            this.setAddItem(contextKey, key, value)
        } else if (formType === this.formTypes[1]) {
            this.setEditItem(contextKey, key, value)
        }
    }

    getAddKey(): string {
        return this.formTypes[0]
    }

    getEditKey(): string {
        return this.formTypes[1]
    }

    getCategoriesSubject(): BehaviorSubject<any[]> | undefined {
        return this._context.categories().subjects.items
    }

    getAwardsSubject(): BehaviorSubject<any[]> | undefined {
        return this._context.awards().subjects.items
    }

    getBenefitsSubject(): BehaviorSubject<any[]> | undefined {
        return this._context.benefits().subjects.items
    }

    getPerksSubject(): BehaviorSubject<any[]> | undefined {
        return this._context.perks().subjects.items
    }

    getSurveysSubject(): BehaviorSubject<any[]> | undefined {
        return this._context.surveys().subjects.items
    }

    getNewsSubject(): BehaviorSubject<any[]> | undefined {
        return this._context.news().subjects.items
    }

    getPromContentSubject(): BehaviorSubject<any[]> | undefined {
        return this._context.promContent().subjects.items
    }

    getLocationsSubject(): BehaviorSubject<any[]> | undefined {
        return this._context.locations().subjects.items
    }

    getAddCategorySubject(): BehaviorSubject<any> | undefined {
        return this._context.categories().subjects.currentAddItem
    }

    getAddAwardSubject(): BehaviorSubject<any> | undefined {
        return this._context.awards().subjects.currentAddItem
    }

    getAddBenefitSubject(): BehaviorSubject<any> | undefined {
        return this._context.benefits().subjects.currentAddItem
    }

    getAddPerkSubject(): BehaviorSubject<any> | undefined {
        return this._context.perks().subjects.currentAddItem
    }

    getAddSurveySubject(): BehaviorSubject<any> | undefined {
        return this._context.surveys().subjects.currentAddItem
    }

    getAddNewsSubject(): BehaviorSubject<any> | undefined {
        return this._context.news().subjects.currentAddItem
    }

    getAddPromContentSubject(): BehaviorSubject<any> | undefined {
        return this._context.promContent().subjects.currentAddItem
    }

    getAddLocationSubject(): BehaviorSubject<any> | undefined {
        return this._context.locations().subjects.currentAddItem
    }

    getEditCategorySubject(): BehaviorSubject<any> | undefined {
        return this._context.categories().subjects.currentEditItem
    }

    getEditAwardSubject(): BehaviorSubject<any> | undefined {
        return this._context.awards().subjects.currentEditItem
    }

    getEditBenefitSubject(): BehaviorSubject<any> | undefined {
        return this._context.benefits().subjects.currentEditItem
    }

    getEditPerkSubject(): BehaviorSubject<any> | undefined {
        return this._context.perks().subjects.currentEditItem
    }

    getEditSurveySubject(): BehaviorSubject<any> | undefined {
        return this._context.surveys().subjects.currentEditItem
    }

    getEditNewsSubject(): BehaviorSubject<any> | undefined {
        return this._context.news().subjects.currentEditItem
    }

    getEditPromContentSubject(): BehaviorSubject<any> | undefined {
        return this._context.promContent().subjects.currentEditItem
    }

    getEditLocationSubject(): BehaviorSubject<any> | undefined {
        return this._context.locations().subjects.currentEditItem
    }

    getCategoriesResultSubject(): BehaviorSubject<IResult | null> | undefined {
        return this._context.categories().subjects.result
    }

    getAwardsResultSubject(): BehaviorSubject<IResult | null> | undefined {
        return this._context.awards().subjects.result
    }

    getBenefitResultSubject(): BehaviorSubject<IResult | null> | undefined {
        return this._context.benefits().subjects.result
    }

    getPerksResultSubject(): BehaviorSubject<IResult | null> | undefined {
        return this._context.perks().subjects.result
    }

    getSurveysResultSubject(): BehaviorSubject<IResult | null> | undefined {
        return this._context.surveys().subjects.result
    }

    getNewsResultSubject(): BehaviorSubject<IResult | null> | undefined {
        return this._context.news().subjects.result
    }

    getPromContentResultSubject(): BehaviorSubject<IResult | null> | undefined {
        return this._context.promContent().subjects.result
    }

    getLocationsResultSubject(): BehaviorSubject<IResult | null> | undefined {
        return this._context.locations().subjects.result
    }

    setCategories(value: any[]) {
        this._context.categories().subjects.items.next(value)
    }

    setAwards(value: any[]) {
        this._context.awards().subjects.items.next(value)
    }

    setBenefits(value: any[]) {
        this._context.benefits().subjects.items.next(value)
    }

    setPerks(value: any[]) {
        this._context.perks().subjects.items.next(value)
    }

    setSurveys(value: any[]) {
        this._context.surveys().subjects.items.next(value)
    }

    setNews(value: any[]) {
        this._context.news().subjects.items.next(value)
    }

    setPromContent(value: PromotionalContent[]) {
        this._context.promContent().subjects.items.next(value)
    }

    setLocations(value: Location[]) {
        this._context.locations().subjects.items.next(value)
    }

    resetCategories() {
        this.categoryService
            .Get()
            .pipe(first())
            .subscribe((categories: Category[]) => {
                this.setCategories(categories)
            })
    }

    resetAwards() {
        this.awardsService
            .Get()
            .pipe(first())
            .subscribe((awards: any[]) => {
                this.setAwards(awards)
            })
    }

    resetBenefits() {
        this.benefitService
            .Get()
            .pipe(first())
            .subscribe((benefits: any[]) => {
                this.setBenefits(benefits)
            })
    }

    resetPerks() {
        this.perksService
            .Get()
            .pipe(first())
            .subscribe((perks: any[]) => {
                this.setPerks(perks)
            })
    }

    resetSurveys() {
        this.surveysService
            .Get()
            .pipe(first())
            .subscribe((surveys: any[]) => {
                this.setSurveys(surveys)
            })
    }

    resetNews() {
        this.newsService
            .Get()
            .pipe(first())
            .subscribe((news: any[]) => {
                this.setNews(news)
            })
    }

    resetPromContent() {
        this.promContentService
            .Get()
            .pipe(first())
            .subscribe(pcon => {
                this.setPromContent(pcon)
            })
    }

    resetLocations() {
        this.locationsService
            .Get()
            .pipe(first())
            .subscribe(locations => {
                this.setLocations(locations)
            })
    }

    getCategoriesKey(): string {
        return this._context.categories().getKey()
    }

    getAwardsKey(): string {
        return this._context.awards().getKey()
    }

    getBenefitsKey(): string {
        return this._context.benefits().getKey()
    }

    getPerksKey(): string {
        return this._context.perks().getKey()
    }

    getSurveysKey(): string {
        return this._context.surveys().getKey()
    }

    getNewsKey(): string {
        return this._context.news().getKey()
    }

    getPromContentKey(): string {
        return this._context.promContent().getKey()
    }

    getLocationsKey(): string {
        return this._context.locations().getKey()
    }

    saveCategory() {
        if (this._context.categories() !== undefined) {
            const category = this._context.categories().subjects.currentAddItem.value
            if (category !== undefined) {
                if (category.parentId === "") {
                    category.parentId = null
                }
                this.addCategory(category)
            }
        }
    }

    saveAward() {
        if (this._context.awards() !== undefined) {
            const award = this._context.awards().subjects.currentAddItem.value
            if (award !== undefined) {
                this.addAward(award)
            }
        }
    }

    saveBenefit() {
        if (this._context.benefits() !== undefined) {
            const benefit = this._context.benefits().subjects.currentAddItem.value
            if (benefit !== undefined) {
                this.addBenefit(benefit)
            }
        }
    }

    savePerk() {
        if (this._context.perks() !== undefined) {
            const perk = this._context.perks().subjects.currentAddItem.value
            if (perk !== undefined) {
                this.addPerk(perk)
            }
        }
    }

    saveSurvey() {
        if (this._context.surveys() !== undefined) {
            const survey = this._context.surveys().subjects.currentAddItem.value
            if (survey !== undefined) {
                this.addSurvey(survey)
            }
        }
    }

    saveNews() {
        if (this._context.news() !== undefined) {
            const news = this._context.news().subjects.currentAddItem.value
            if (news !== undefined) {
                this.addNews(news)
            }
        }
    }

    savePromContent() {
        if (this._context.promContent() !== undefined) {
            const pcon = this._context.promContent().subjects.currentAddItem.value
            if (pcon !== undefined) {
                this.addPromContent(pcon)
            }
        }
    }

    saveLocation() {
        if (this._context.locations() !== undefined) {
            const location = this._context.locations().subjects.currentAddItem.value
            if (location !== undefined) {
                this.addLocation(location)
            }
        }
    }

    draftCategory() {
        if (this._context.categories() !== undefined) {
            const category = this._context.categories().subjects.currentAddItem.value
            if (category !== undefined) {
                const draftCategory = { ...category, isDraft: true }
                if (draftCategory.parentId === "") {
                    draftCategory.parentId = null
                }
                this.addCategory(draftCategory)
            }
        }
    }

    draftAward() {
        if (this._context.awards() !== undefined) {
            const award = this._context.awards().subjects.currentAddItem.value
            if (award !== undefined) {
                const draftAward = { ...award, isDraft: true }
                this.addAward(draftAward)
            }
        }
    }

    draftBenefit() {
        if (this._context.benefits() !== undefined) {
            const benefit = this._context.benefits().subjects.currentAddItem.value
            if (benefit !== undefined) {
                const draftBenefit = { ...benefit, isDraft: true }
                this.addBenefit(draftBenefit)
            }
        }
    }

    draftPerk() {
        if (this._context.perks() !== undefined) {
            const perk = this._context.perks().subjects.currentAddItem.value
            if (perk !== undefined) {
                const draftPerk = { ...perk, isDraft: true }
                this.addPerk(draftPerk)
            }
        }
    }

    draftSurvey() {
        if (this._context.surveys() !== undefined) {
            const survey = this._context.surveys().subjects.currentAddItem.value
            if (survey !== undefined) {
                const draftSurvey = { ...survey, isDraft: true }
                this.addSurvey(draftSurvey)
            }
        }
    }

    draftNews() {
        if (this._context.news() !== undefined) {
            const news = this._context.news().subjects.currentAddItem.value
            if (news !== undefined) {
                const draftNews = { ...news, isDraft: true }
                this.addNews(draftNews)
            }
        }
    }

    draftPromContent() {
        if (this._context.promContent() !== undefined) {
            const pcon = this._context.promContent().subjects.currentAddItem.value
            if (pcon !== undefined) {
                const draftPcon = { ...pcon, isDraft: true }
                this.addPromContent(draftPcon)
            }
        }
    }

    draftLocation() {
        if (this._context.locations() !== undefined) {
            const location = this._context.locations().subjects.currentAddItem.value
            if (location !== undefined) {
                const draftLocation = { ...location, isDraft: true }
                this.addLocation(draftLocation)
            }
        }
    }

    modifyCategory() {
        if (this._context.categories() !== undefined) {
            const newCat = JSON.parse(JSON.stringify(this._context.categories().subjects.currentEditItem.value))
            if (newCat !== undefined && this._context.categories().currentEditedMasterId !== undefined) {
                if (newCat.isDeleted === true) {
                    this.deleteCategory(this._context.categories().currentEditedMasterId!)
                } else {
                    if (newCat.parentId === "") {
                        newCat.parentId = null
                    }
                    const oldValue = this._context
                        .categories()
                        .subjects.items.value.find(c => c.masterId === this._context.categories().currentEditedMasterId)
                    const oldCat = ModifyCategoryFactory.from(oldValue)
                    const patchDocument = this.jsonPatchDocumentGenerator.getJsonPatchDocument(
                        oldCat,
                        newCat,
                        this.ignoreFields
                    )
                    const modifyObject = ModifyObjectFactory.create(patchDocument, oldValue!.version)
                    this.editCategory(this._context.categories().currentEditedMasterId!, modifyObject)
                }
            }
        }
    }

    modifyAward() {
        if (this._context.awards() !== undefined) {
            const newAward = JSON.parse(JSON.stringify(this._context.awards().subjects.currentEditItem.value))
            if (newAward !== undefined && this._context.awards().currentEditedMasterId !== undefined) {
                if (newAward.isDeleted === true) {
                    this.deleteAward(this._context.awards().currentEditedMasterId!)
                } else {
                    const oldValue = this._context
                        .awards()
                        .subjects.items.value.find(a => a.masterId === this._context.awards().currentEditedMasterId)
                    const oldAward = ModifyAwardFactory.from(oldValue)
                    const patchDocument = this.jsonPatchDocumentGenerator.getJsonPatchDocument(
                        oldAward,
                        newAward,
                        this.ignoreFields
                    )
                    const modifyObject = ModifyObjectFactory.create(patchDocument, oldValue!.version)
                    this.editAward(this._context.awards().currentEditedMasterId!, modifyObject)
                }
            }
        }
    }

    modifyBenefit() {
        if (this._context.benefits() !== undefined) {
            const newBenefit = JSON.parse(JSON.stringify(this._context.benefits().subjects.currentEditItem.value))
            if (newBenefit !== undefined && this._context.benefits().currentEditedMasterId !== undefined) {
                if (newBenefit.isDeleted == true) {
                    this.deleteBenefit(this._context.benefits().currentEditedMasterId!)
                } else {
                    const oldValue = this._context
                        .benefits()
                        .subjects.items.value.find(b => b.masterId === this._context.benefits().currentEditedMasterId)
                    const oldBenefit = ModifyBenefitFactory.from(oldValue)
                    const patchDocument = this.jsonPatchDocumentGenerator.getJsonPatchDocument(
                        oldBenefit,
                        newBenefit,
                        this.ignoreFields
                    )
                    const modifyObject = ModifyObjectFactory.create(patchDocument, oldValue!.version)
                    this.editBenefit(this._context.benefits().currentEditedMasterId!, modifyObject)
                }
            }
        }
    }

    modifyPerk() {
        if (this._context.perks() !== undefined) {
            const newPerk = JSON.parse(JSON.stringify(this._context.perks().subjects.currentEditItem.value))
            if (newPerk !== undefined && this._context.perks().currentEditedMasterId !== undefined) {
                if (newPerk.isDeleted === true) {
                    this.deletePerk(this._context.perks().currentEditedMasterId!)
                } else {
                    const oldValue = this._context
                        .perks()
                        .subjects.items.value.find(p => p.masterId === this._context.perks().currentEditedMasterId)
                    const oldPerk = ModifyPerkFactory.from(oldValue)
                    const patchDocument = this.jsonPatchDocumentGenerator.getJsonPatchDocument(
                        oldPerk,
                        newPerk,
                        this.ignoreFields
                    )
                    const modifyObject = ModifyObjectFactory.create(patchDocument, oldValue!.version)
                    this.editPerk(this._context.perks().currentEditedMasterId!, modifyObject)
                }
            }
        }
    }

    modifySurvey() {
        if (this._context.surveys() !== undefined) {
            const newSurvey = JSON.parse(JSON.stringify(this._context.surveys().subjects.currentEditItem.value))
            if (newSurvey !== undefined && this._context.surveys().currentEditedMasterId !== undefined) {
                if (newSurvey.isDeleted === true) {
                    this.deleteSurvey(this._context.surveys().currentEditedMasterId!)
                } else {
                    const oldValue = this._context
                        .surveys()
                        .subjects.items.value.find(s => s.masterId === this._context.surveys().currentEditedMasterId)
                    const oldSurvey = ModifySurveyFactory.from(oldValue)
                    const patchDocument = this.jsonPatchDocumentGenerator.getJsonPatchDocument(
                        oldSurvey,
                        newSurvey,
                        this.ignoreFields
                    )
                    const modifyObject = ModifyObjectFactory.create(patchDocument, oldValue!.version)
                    this.editSurvey(this._context.surveys().currentEditedMasterId!, modifyObject)
                }
            }
        }
    }

    modifyNews() {
        if (this._context.news() !== undefined) {
            const newNews = JSON.parse(JSON.stringify(this._context.news().subjects.currentEditItem.value))
            if (newNews !== undefined && this._context.news().currentEditedMasterId !== undefined) {
                if (newNews.isDeleted === true) {
                    this.deleteNews(this._context.news().currentEditedMasterId!)
                } else {
                    const oldValue = this._context
                        .news()
                        .subjects.items.value.find(a => a.masterId === this._context.news().currentEditedMasterId)
                    const oldNews = ModifyNewsFactory.from(oldValue)
                    const patchDocument = this.jsonPatchDocumentGenerator.getJsonPatchDocument(
                        oldNews,
                        newNews,
                        this.ignoreFields
                    )
                    const modifyObject = ModifyObjectFactory.create(patchDocument, oldValue!.version)
                    this.editNews(this._context.news().currentEditedMasterId!, modifyObject)
                }
            }
        }
    }

    modifyPromContent() {
        if (this._context.promContent() !== undefined) {
            const newPcon = JSON.parse(JSON.stringify(this._context.promContent().subjects.currentEditItem.value))
            if (newPcon !== undefined && this._context.news().currentEditedMasterId !== undefined) {
                if (newPcon.isDeleted) {
                    this.deleteNews(this._context.promContent().currentEditedMasterId!)
                } else {
                    const oldValue = this._context
                        .promContent()
                        .subjects.items.value.find(
                            a => a.masterId === this._context.promContent().currentEditedMasterId
                        )
                    const oldPcon = ModifyPromotionalContentFactory.from(oldValue)
                    const patchDocument = this.jsonPatchDocumentGenerator.getJsonPatchDocument(
                        oldPcon,
                        newPcon,
                        this.ignoreFields
                    )
                    const modifyObject = ModifyObjectFactory.create(patchDocument, oldValue!.version)
                    this.editPromContent(this._context.promContent().currentEditedMasterId!, modifyObject)
                }
            }
        }
    }

    modifyLocation() {
        if (this._context.locations() !== undefined) {
            const newLocation = JSON.parse(JSON.stringify(this._context.locations().subjects.currentEditItem.value))
            if (newLocation !== undefined && this._context.locations().currentEditedMasterId !== undefined) {
                if (newLocation.isDeleted) {
                    this.deleteLocation(this._context.locations().currentEditedMasterId!)
                } else {
                    const oldValue = this._context
                        .locations()
                        .subjects.items.value.find(l => l.masterId === this._context.locations().currentEditedMasterId)
                    const oldLocation = ModifyLocationFactory.from(oldValue)
                    const patchDocument = this.jsonPatchDocumentGenerator.getJsonPatchDocument(
                        oldLocation,
                        newLocation,
                        this.ignoreFields
                    )
                    const modifyObject = ModifyObjectFactory.create(patchDocument, oldValue!.version)
                    this.editLocation(this._context.locations().currentEditedMasterId!, modifyObject)
                }
            }
        }
    }

    addCategory(category: CreateCategory) {
        this.categoryService
            .Create(category)
            .pipe(first())
            .subscribe({
                next: (category: Category) => {
                    this.setCategories([...this._context.categories().subjects.items.value, category])
                    this._context.categories().resetAddItem()
                    this._context.categories().sendResult(ResultFactory.createSuccess("Category added successfully"))
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.categories().sendResult(ResultFactory.createError(error.error, []))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .categories()
                            .sendResult(
                                ResultFactory.createError(error.error.message, error.error.incorrectFields || [])
                            )
                    } else {
                        this._context.categories().sendResult(ResultFactory.createError("An error occurred", []))
                    }
                },
            })
    }

    addAward(award: any) {
        this.awardsService
            .Create(award)
            .pipe(first())
            .subscribe({
                next: (award: any) => {
                    this.setAwards([...this._context.awards().subjects.items.value, award])
                    this._context.awards().resetAddItem()
                    this.ResetFilteredEmployees()
                    this._context.awards().sendResult(ResultFactory.createSuccess("Award added successfully"))
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.awards().sendResult(ResultFactory.createError(error.error, []))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .awards()
                            .sendResult(
                                ResultFactory.createError(error.error.message, error.error.incorrectFields || [])
                            )
                    } else {
                        this._context.awards().sendResult(ResultFactory.createError("An error occurred", []))
                    }
                },
            })
    }

    addBenefit(benefit: any) {
        this.benefitService
            .Create(benefit)
            .pipe(first())
            .subscribe({
                next: (benefit: any) => {
                    this.setBenefits([...this._context.benefits().subjects.items.value, benefit])
                    this._context.benefits().resetAddItem()
                    this.ResetFilteredEmployees()
                    this._context.benefits().sendResult(ResultFactory.createSuccess("Benefit added successfully"))
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.benefits().sendResult(ResultFactory.createError(error.error, []))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .benefits()
                            .sendResult(
                                ResultFactory.createError(error.error.message, error.error.incorrectFields || [])
                            )
                    } else {
                        this._context.benefits().sendResult(ResultFactory.createError("An error occurred", []))
                    }
                },
            })
    }

    addPerk(perk: any) {
        this.perksService
            .Create(perk)
            .pipe(first())
            .subscribe({
                next: (perk: any) => {
                    this.setPerks([...this._context.perks().subjects.items.value, perk])
                    this._context.perks().resetAddItem()
                    this.ResetFilteredEmployees()
                    this._context.perks().sendResult(ResultFactory.createSuccess("Perk added successfully"))
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.perks().sendResult(ResultFactory.createError(error.error, []))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .perks()
                            .sendResult(
                                ResultFactory.createError(error.error.message, error.error.incorrectFields || [])
                            )
                    } else {
                        this._context.perks().sendResult(ResultFactory.createError("An error occurred", []))
                    }
                },
            })
    }

    addSurvey(survey: any) {
        this.surveysService
            .Create(survey)
            .pipe(first())
            .subscribe({
                next: (survey: any) => {
                    this.setSurveys([...this._context.surveys().subjects.items.value, survey])
                    this._context.surveys().resetAddItem()
                    this._context.surveys().sendResult(ResultFactory.createSuccess("Survey added successfully"))
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.surveys().sendResult(ResultFactory.createError(error.error, []))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .surveys()
                            .sendResult(
                                ResultFactory.createError(error.error.message, error.error.incorrectFields || [])
                            )
                    } else {
                        this._context.surveys().sendResult(ResultFactory.createError("An error occurred", []))
                    }
                },
            })
    }

    addNews(news: any) {
        this.newsService
            .Create(news)
            .pipe(first())
            .subscribe({
                next: (news: any) => {
                    this.setNews([...this._context.news().subjects.items.value, news])
                    this._context.news().resetAddItem()
                    this._context.news().sendResult(ResultFactory.createSuccess("News added successfully"))
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.news().sendResult(ResultFactory.createError(error.error, []))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .news()
                            .sendResult(
                                ResultFactory.createError(error.error.message, error.error.incorrectFields || [])
                            )
                    } else {
                        this._context.news().sendResult(ResultFactory.createError("An error occurred", []))
                    }
                },
            })
    }

    addPromContent(pcon: CreatePromotionalContent) {
        this.promContentService
            .Create(pcon)
            .pipe(first())
            .subscribe({
                next: res => {
                    this.setPromContent([...this._context.promContent().subjects.items.value, res])
                    this._context.promContent().resetAddItem()
                    this._context
                        .promContent()
                        .sendResult(ResultFactory.createSuccess("Promotional content added successfully"))
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.promContent().sendResult(ResultFactory.createError(error.error, []))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .promContent()
                            .sendResult(
                                ResultFactory.createError(error.error.message, error.error.incorrectFields || [])
                            )
                    } else {
                        this._context.promContent().sendResult(ResultFactory.createError("An error occurred", []))
                    }
                },
            })
    }

    addLocation(location: CreateLocation) {
        this.locationsService
            .Create(location)
            .pipe(first())
            .subscribe({
                next: res => {
                    this.setLocations([...this._context.locations().subjects.items.value, res])
                    this._context.locations().resetAddItem()
                    this._context.locations().sendResult(ResultFactory.createSuccess("Location added successfully"))
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.locations().sendResult(ResultFactory.createError(error.error, []))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .locations()
                            .sendResult(
                                ResultFactory.createError(error.error.message, error.error.incorrectFields || [])
                            )
                    } else {
                        this._context.locations().sendResult(ResultFactory.createError("An error occurred", []))
                    }
                },
            })
    }

    editCategory(masterId: string, modifyObject: ModifyObject) {
        this.categoryService
            .Modify(masterId, modifyObject)
            .pipe(first())
            .subscribe({
                next: (category: Category) => {
                    this.setCategories(
                        this._context
                            .categories()
                            .subjects.items.value.map(c => (c.masterId === masterId ? category : c))
                    )
                    this._context
                        .categories()
                        .sendResult(ResultFactory.createSuccess("Category edited successfully", this.formTypes[1]))
                    this.setCurrentEditCategory(undefined)
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context
                            .categories()
                            .sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .categories()
                            .sendResult(
                                ResultFactory.createError(
                                    error.error.message,
                                    error.error.incorrectFields || [],
                                    this.formTypes[1]
                                )
                            )
                    } else {
                        this._context
                            .categories()
                            .sendResult(ResultFactory.createError("An error occurred", [], this.formTypes[1]))
                    }
                },
            })
    }

    editAward(masterId: string, modifyObject: ModifyObject) {
        this.awardsService
            .Modify(masterId, modifyObject)
            .pipe(first())
            .subscribe({
                next: (award: any) => {
                    this.setAwards(
                        this._context.awards().subjects.items.value.map(a => (a.masterId === masterId ? award : a))
                    )
                    this._context
                        .awards()
                        .sendResult(ResultFactory.createSuccess("Award edited successfully", this.formTypes[1]))
                    this.setCurrentEditAward(undefined)
                    this.ResetFilteredEmployees()
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.awards().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .awards()
                            .sendResult(
                                ResultFactory.createError(
                                    error.error.message,
                                    error.error.incorrectFields || [],
                                    this.formTypes[1]
                                )
                            )
                    } else {
                        this._context
                            .awards()
                            .sendResult(ResultFactory.createError("An error occurred", [], this.formTypes[1]))
                    }
                },
            })
    }

    editBenefit(masterId: string, modifyObject: ModifyObject) {
        this.benefitService
            .Modify(masterId, modifyObject)
            .pipe(first())
            .subscribe({
                next: (benefit: any) => {
                    this.setBenefits(
                        this._context.benefits().subjects.items.value.map(b => (b.masterId === masterId ? benefit : b))
                    )
                    this._context
                        .benefits()
                        .sendResult(ResultFactory.createSuccess("Benefit edited successfully", this.formTypes[1]))
                    this.setCurrentEditBenefit(undefined)
                    this.ResetFilteredEmployees()
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context
                            .benefits()
                            .sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .benefits()
                            .sendResult(
                                ResultFactory.createError(
                                    error.error.message,
                                    error.error.incorrectFields || [],
                                    this.formTypes[1]
                                )
                            )
                    } else {
                        this._context
                            .benefits()
                            .sendResult(ResultFactory.createError("An error occurred", [], this.formTypes[1]))
                    }
                },
            })
    }

    editPerk(masterId: string, modifyObject: ModifyObject) {
        this.perksService
            .Modify(masterId, modifyObject)
            .pipe(first())
            .subscribe({
                next: (perk: any) => {
                    this.setPerks(
                        this._context.perks().subjects.items.value.map(p => (p.masterId === masterId ? perk : p))
                    )
                    this._context
                        .perks()
                        .sendResult(ResultFactory.createSuccess("Perk edited successfully", this.formTypes[1]))
                    this.setCurrentEditPerk(undefined)
                    this.ResetFilteredEmployees()
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.perks().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .perks()
                            .sendResult(
                                ResultFactory.createError(
                                    error.error.message,
                                    error.error.incorrectFields || [],
                                    this.formTypes[1]
                                )
                            )
                    } else {
                        this._context
                            .perks()
                            .sendResult(ResultFactory.createError("An error occurred", [], this.formTypes[1]))
                    }
                },
            })
    }

    editSurvey(masterId: string, modifyObject: ModifyObject) {
        this.surveysService
            .Modify(masterId, modifyObject)
            .pipe(first())
            .subscribe({
                next: (survey: any) => {
                    this.setSurveys(
                        this._context.surveys().subjects.items.value.map(s => (s.masterId === masterId ? survey : s))
                    )
                    this._context
                        .surveys()
                        .sendResult(ResultFactory.createSuccess("Survey edited successfully", this.formTypes[1]))
                    this.setCurrentEditSurvey(undefined)
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context
                            .surveys()
                            .sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .surveys()
                            .sendResult(
                                ResultFactory.createError(
                                    error.error.message,
                                    error.error.incorrectFields || [],
                                    this.formTypes[1]
                                )
                            )
                    } else {
                        this._context
                            .surveys()
                            .sendResult(ResultFactory.createError("An error occurred", [], this.formTypes[1]))
                    }
                },
            })
    }

    editNews(masterId: string, modifyObject: ModifyObject) {
        this.newsService
            .Modify(masterId, modifyObject)
            .pipe(first())
            .subscribe({
                next: (news: any) => {
                    this.setNews(
                        this._context.news().subjects.items.value.map(a => (a.masterId === masterId ? news : a))
                    )
                    this._context
                        .news()
                        .sendResult(ResultFactory.createSuccess("News edited successfully", this.formTypes[1]))
                    this.setCurrentEditNews(undefined)
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context.news().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .news()
                            .sendResult(
                                ResultFactory.createError(
                                    error.error.message,
                                    error.error.incorrectFields || [],
                                    this.formTypes[1]
                                )
                            )
                    } else {
                        this._context
                            .news()
                            .sendResult(ResultFactory.createError("An error occurred", [], this.formTypes[1]))
                    }
                },
            })
    }

    editPromContent(masterId: string, modifyObject: ModifyObject) {
        this.promContentService
            .Modify(masterId, modifyObject)
            .pipe(first())
            .subscribe({
                next: pcon => {
                    this.setPromContent(
                        this._context.promContent().subjects.items.value.map(a => (a.masterId === masterId ? pcon : a))
                    )
                    this._context
                        .promContent()
                        .sendResult(
                            ResultFactory.createSuccess("Promotional content edited successfully", this.formTypes[1])
                        )
                    this.setCurrentEditPromContent(undefined)
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context
                            .promContent()
                            .sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .promContent()
                            .sendResult(
                                ResultFactory.createError(
                                    error.error.message,
                                    error.error.incorrectFields || [],
                                    this.formTypes[1]
                                )
                            )
                    } else {
                        this._context
                            .promContent()
                            .sendResult(ResultFactory.createError("An error occurred", [], this.formTypes[1]))
                    }
                },
            })
    }

    editLocation(masterId: string, modifyObject: ModifyObject) {
        this.locationsService
            .Modify(masterId, modifyObject)
            .pipe(first())
            .subscribe({
                next: res => {
                    this.setLocations(
                        this._context.locations().subjects.items.value.map(l => (l.masterId === masterId ? res : l))
                    )
                    this._context
                        .locations()
                        .sendResult(ResultFactory.createSuccess("Location edited successfully", this.formTypes[1]))
                    this.setCurrentEditLocation(undefined)
                },
                error: error => {
                    if (typeof error.error === "string") {
                        this._context
                            .locations()
                            .sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                    } else if (typeof error.error === "object" && error.error.message) {
                        this._context
                            .locations()
                            .sendResult(
                                ResultFactory.createError(
                                    error.error.message,
                                    error.error.incorrectFields || [],
                                    this.formTypes[1]
                                )
                            )
                    } else {
                        this._context
                            .locations()
                            .sendResult(ResultFactory.createError("An error occurred", [], this.formTypes[1]))
                    }
                },
            })
    }

    deleteCategory(masterId: string) {
        this.categoryService
            .Delete(masterId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.setCategories(
                        this._context.categories().subjects.items.value.filter(c => c.masterId !== masterId)
                    )
                    this._context.categories().resetEditItem(undefined)
                    this._context
                        .categories()
                        .sendResult(ResultFactory.createSuccess("Category deleted successfully", this.formTypes[1]))
                    this.setCurrentEditCategory(undefined)
                },
                error: error => {
                    this._context.categories().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                },
            })
    }

    deleteAward(masterId: string) {
        this.awardsService
            .Delete(masterId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.setAwards(this._context.awards().subjects.items.value.filter(a => a.masterId !== masterId))
                    this._context.awards().resetEditItem(undefined)
                    this._context
                        .awards()
                        .sendResult(ResultFactory.createSuccess("Award deleted successfully", this.formTypes[1]))
                    this.setCurrentEditAward(undefined)
                    this.ResetFilteredEmployees()
                },
                error: error => {
                    this._context.awards().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                },
            })
    }

    deleteBenefit(masterId: string) {
        this.benefitService
            .Delete(masterId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.setBenefits(this._context.benefits().subjects.items.value.filter(b => b.masterId !== masterId))
                    this._context.benefits().resetEditItem(undefined)
                    this._context
                        .benefits()
                        .sendResult(ResultFactory.createSuccess("Benefit deleted successfully", this.formTypes[1]))
                    this.setCurrentEditBenefit(undefined)
                    this.ResetFilteredEmployees()
                },
                error: error => {
                    this._context.benefits().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                },
            })
    }

    deletePerk(masterId: string) {
        this.perksService
            .Delete(masterId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.setPerks(this._context.perks().subjects.items.value.filter(p => p.masterId !== masterId))
                    this._context.perks().resetEditItem(undefined)
                    this._context
                        .perks()
                        .sendResult(ResultFactory.createSuccess("Perk deleted successfully", this.formTypes[1]))
                    this.setCurrentEditPerk(undefined)
                    this.ResetFilteredEmployees()
                },
                error: error => {
                    this._context.perks().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                },
            })
    }

    deleteSurvey(masterId: string) {
        this.surveysService
            .Delete(masterId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.setSurveys(this._context.surveys().subjects.items.value.filter(s => s.masterId !== masterId))
                    this._context.surveys().resetEditItem(undefined)
                    this._context
                        .surveys()
                        .sendResult(ResultFactory.createSuccess("Survey deleted successfully", this.formTypes[1]))
                    this.setCurrentEditSurvey(undefined)
                },
                error: error => {
                    this._context.surveys().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                },
            })
    }

    deleteNews(masterId: string) {
        this.newsService
            .Delete(masterId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.setNews(this._context.news().subjects.items.value.filter(a => a.masterId !== masterId))
                    this._context.news().resetEditItem(undefined)
                    this._context
                        .news()
                        .sendResult(ResultFactory.createSuccess("News deleted successfully", this.formTypes[1]))
                    this.setCurrentEditNews(undefined)
                },
                error: error => {
                    this._context.news().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                },
            })
    }

    deletePromContent(masterId: string) {
        this.promContentService
            .Delete(masterId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.setPromContent(
                        this._context.promContent().subjects.items.value.filter(a => a.masterId !== masterId)
                    )
                    this._context.promContent().resetEditItem(undefined)
                    this._context
                        .promContent()
                        .sendResult(ResultFactory.createSuccess("News deleted successfully", this.formTypes[1]))
                    this.setCurrentEditPromContent(undefined)
                },
                error: error => {
                    this._context
                        .promContent()
                        .sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                },
            })
    }

    deleteLocation(masterId: string) {
        this.locationsService
            .Delete(masterId)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.setLocations(
                        this._context.locations().subjects.items.value.filter(l => l.masterId !== masterId)
                    )
                    this._context.locations().resetEditItem(undefined)
                    this._context
                        .locations()
                        .sendResult(ResultFactory.createSuccess("Location deleted successfully", this.formTypes[1]))
                    this.setCurrentEditLocation(undefined)
                },
                error: error => {
                    this._context.locations().sendResult(ResultFactory.createError(error.error, [], this.formTypes[1]))
                },
            })
    }

    setCurrentEditCategory(masterId: string | undefined) {
        setCurrentEditedMasterId(this._context.categories(), masterId)
        this._context.categories().resetEditItem(masterId)
    }

    setCurrentEditAward(masterId: string | undefined) {
        setCurrentEditedMasterId(this._context.awards(), masterId)
        this._context.awards().resetEditItem(masterId)
    }

    setCurrentEditBenefit(masterId: string | undefined) {
        setCurrentEditedMasterId(this._context.benefits(), masterId)
        this._context.benefits().resetEditItem(masterId)
    }

    setCurrentEditPerk(masterId: string | undefined) {
        setCurrentEditedMasterId(this._context.perks(), masterId)
        this._context.perks().resetEditItem(masterId)
    }

    setCurrentEditSurvey(masterId: string | undefined) {
        setCurrentEditedMasterId(this._context.surveys(), masterId)
        this._context.surveys().resetEditItem(masterId)
    }

    setCurrentEditNews(masterId: string | undefined) {
        setCurrentEditedMasterId(this._context.news(), masterId)
        this._context.news().resetEditItem(masterId)
    }

    setCurrentEditPromContent(masterId: string | undefined) {
        setCurrentEditedMasterId(this._context.promContent(), masterId)
        this._context.promContent().resetEditItem(masterId)
    }

    setCurrentEditLocation(masterId: string | undefined) {
        setCurrentEditedMasterId(this._context.locations(), masterId)
        this._context.locations().resetEditItem(masterId)
    }

    currentEditedCategory(): string | undefined {
        return this._context.categories()?.currentEditedMasterId
    }

    currentEditedAward(): string | undefined {
        return this._context.awards()?.currentEditedMasterId
    }

    currentEditedBenefit(): string | undefined {
        return this._context.benefits()?.currentEditedMasterId
    }

    currentEditedPerk(): string | undefined {
        return this._context.perks()?.currentEditedMasterId
    }

    currentEditedSurvey(): string | undefined {
        return this._context.surveys()?.currentEditedMasterId
    }

    currentEditedNews(): string | undefined {
        return this._context.news()?.currentEditedMasterId
    }

    currentEditedPromContent(): string | undefined {
        return this._context.promContent()?.currentEditedMasterId
    }

    currentEditedLocation(): string | undefined {
        return this._context.locations()?.currentEditedMasterId
    }

    categoryName(masterid: string) {
        if (masterid != null) {
            for (let item of this._context.categories().subjects.items.value) {
                if (item.masterId === masterid) {
                    return item.names.hr
                }
            }
        } else {
            return masterid
        }
    }

    categoriesNames(masterids: string[]) {
        let categoriesNames: string[] = []
        if (masterids.length > 0) {
            for (let categoryid of masterids) {
                for (let item of this._context.categories().subjects.items.value) {
                    if (item.masterId === categoryid) {
                        categoriesNames.push(item.names.hr)
                    }
                }
            }
            return categoriesNames
        } else {
            return masterids
        }
    }
}
