<valben-header [contentType]="contentType" [editEnabled]="editEnabled" (contentTypeChange)="setContentType($event)">
</valben-header>

<div
    class="relative grow overflow-y-auto"
    [ngSwitch]="contentType"
    [ngClass]="{'blur-[2px] pointer-events-none [&_*]:select-none': loading$ | async}"
>
    <div *ngIf="loading$ | async" class="absolute flex left-1/2 top-1/2 justify-center items-center z-50">
        <div class="pi pi-spin pi-spinner text-[10rem] text-black/80"></div>
    </div>

    <div *ngSwitchCase="0">
        <valben-table (editEvent)="editOnClick($event)" [columns]="cols" [values]="tableValues" [refresh]="refresh">
        </valben-table>
    </div>

    <div *ngSwitchCase="1" class="flex flex-col gap-4 bg-valamargray border-2 static p-4">
        <button class="flex valben-button text-lg ml-auto" (click)="save()">Spremi</button>
        <valben-dictionary-input
            [textArea]="false"
            [key]="createPerkKeys.Names"
            [itemObservable]="addItemObservable"
            [update]="updateAddNames"
            [currentItem]="currentAddItem"
            title="Naziv"
            [error]="cErrors[createPerkKeys.Names]"
        >
        </valben-dictionary-input>
        <valben-dictionary-input
            [textArea]="true"
            [key]="createPerkKeys.Descriptions"
            [itemObservable]="addItemObservable"
            [update]="updateAddDescriptions"
            [currentItem]="currentAddItem"
            title="Opis"
            [error]="cErrors[createPerkKeys.Descriptions]"
        >
        </valben-dictionary-input>
        <valben-boolean-input
            [key]="createPerkKeys.IsActive"
            [itemObservable]="addItemObservable"
            [update]="updateAddIsActive"
            title="Aktivan"
            [optional]="true"
            [error]="cErrors[createPerkKeys.IsActive]"
        >
        </valben-boolean-input>
        <valben-file-input
            [key]="createPerkKeys.Image"
            [itemObservable]="addItemObservable"
            [update]="updateAddImage"
            title="Prenesi sliku ili direktni url postojeće slike"
            fileType="image/*"
            [error]="cErrors[createPerkKeys.Image]"
        >
        </valben-file-input>
        <valben-text-input
            [key]="createPerkKeys.Link"
            [itemObservable]="addItemObservable"
            [update]="updateAddLink"
            title="Link"
            [error]="cErrors[createPerkKeys.Link]"
        >
        </valben-text-input>
        <valben-contact-input
            [key]="createPerkKeys.Contact"
            [itemObservable]="addItemObservable"
            [update]="updateAddContact"
            [currentItem]="currentAddItem"
            title="Kontakt"
            [error]="cErrors[createPerkKeys.Contact]"
        >
        </valben-contact-input>
        <valben-categories-multiple-choice
            [itemObservable]="addItemObservable"
            [update]="updateAddCategories"
            [key]="createPerkKeys.CategoryIds"
            [type]="categoriesKey"
            title="Kategorije"
            [error]="cErrors[createPerkKeys.CategoryIds]"
        >
        </valben-categories-multiple-choice>
        <valben-locations-multiple-choice
            [key]="createPerkKeys.LocationIds"
            [itemObservable]="addItemObservable"
            [update]="updateAddLocations"
            [error]="cErrors[createPerkKeys.LocationIds]"
            title="Lokacije"
        >
        </valben-locations-multiple-choice>
        <valben-dependencies
            [title]="'Dependencies'"
            [itemObservable]="addItemObservable"
            [key]="createPerkKeys.Dependencies"
            [update]="updateAddDependencies"
            [error]="cErrors[createPerkKeys.Dependencies]"
        ></valben-dependencies>
        <div class="flex gap-4 justify-end items-center select-none">
            <button class="text-lg valben-button" (click)="draft()">Skica</button>
            <button class="text-lg valben-button" (click)="save()">Spremi</button>
        </div>
    </div>

    <div
        *ngSwitchCase="2"
        class="flex flex-col gap-4 bg-valamargray border-2 static p-4"
        [ngClass]="(loading$ | async) ? 'blur-[2px] pointer-events-none [&_*]:select-none' : ''"
    >
        <button class="flex valben-button text-lg ml-auto" (click)="modify()">Spremi</button>
        <valben-dictionary-input
            [textArea]="false"
            [key]="modifyPerkKeys.Names"
            [itemObservable]="editItemObservable"
            [update]="updateEditNames"
            [currentItem]="currentEditItem"
            title="Naziv"
            [error]="mErrors[modifyPerkKeys.Names]"
        >
        </valben-dictionary-input>
        <valben-dictionary-input
            [textArea]="true"
            [key]="modifyPerkKeys.Descriptions"
            [itemObservable]="editItemObservable"
            [update]="updateEditDescriptions"
            [currentItem]="currentEditItem"
            title="Opis"
            [error]="mErrors[modifyPerkKeys.Descriptions]"
        >
        </valben-dictionary-input>
        <valben-boolean-input
            [key]="modifyPerkKeys.IsActive"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsActive"
            title="Aktivan"
            [optional]="true"
            [error]="mErrors[modifyPerkKeys.IsActive]"
        >
        </valben-boolean-input>
        <valben-boolean-input
            [key]="modifyPerkKeys.IsDraft"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsDraft"
            title="Skica"
            [optional]="true"
            [error]="mErrors[modifyPerkKeys.IsDraft]"
        >
        </valben-boolean-input>
        <valben-boolean-input
            [key]="modifyPerkKeys.IsDeleted"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsDeleted"
            title="Obriši"
            [optional]="true"
            [error]="mErrors[modifyPerkKeys.IsDeleted]"
        >
        </valben-boolean-input>
        <valben-file-input
            [key]="modifyPerkKeys.Image"
            [itemObservable]="editItemObservable"
            [update]="updateEditImage"
            title="Prenesi sliku ili direktni url postojeće slike"
            fileType="image/*"
            [error]="mErrors[createPerkKeys.Image]"
        >
        </valben-file-input>
        <valben-text-input
            [key]="modifyPerkKeys.Link"
            [itemObservable]="editItemObservable"
            [update]="updateEditLink"
            title="Link"
            [error]="mErrors[modifyPerkKeys.Link]"
        >
        </valben-text-input>
        <valben-contact-input
            [key]="modifyPerkKeys.Contact"
            [itemObservable]="editItemObservable"
            [update]="updateEditContact"
            [currentItem]="currentEditItem"
            title="Kontakt"
            [error]="mErrors[modifyPerkKeys.Contact]"
        >
        </valben-contact-input>
        <valben-categories-multiple-choice
            [itemObservable]="editItemObservable"
            [update]="updateEditCategories"
            [key]="modifyPerkKeys.CategoryIds"
            [type]="categoriesKey"
            title="Kategorije"
            [error]="mErrors[modifyPerkKeys.CategoryIds]"
        >
        </valben-categories-multiple-choice>
        <valben-locations-multiple-choice
            [key]="modifyPerkKeys.LocationIds"
            [itemObservable]="editItemObservable"
            [update]="updateEditLocations"
            [error]="mErrors[modifyPerkKeys.LocationIds]"
            title="Lokacije"
        ></valben-locations-multiple-choice>
        <valben-dependencies
            [title]="'Dependencies'"
            [itemObservable]="editItemObservable"
            [key]="modifyPerkKeys.Dependencies"
            [update]="updateEditDependencies"
            [error]="mErrors[modifyPerkKeys.Dependencies]"
        ></valben-dependencies>
        <button class="flex valben-button text-lg ml-auto" (click)="modify()">Spremi</button>
    </div>
</div>
